<template lang="pug">
  v-menu(
    v-model="menu"
    :close-on-content-click="false"
    max-width="100%"
    :content-class="menuClasses"
  )
    template(v-slot:activator="{ on }")
      slot(
        name="activator"
        :on="on"
        :show="showDatepicker"
        :formattedDate="formattedDate"
        :showDatepicker="showDatepicker"
        :clearDatepicker="clearDatepicker"
      )
        v-text-field(
          :value="timepicker ? value : formattedDate"
          clearable
          :hide-details="hideDetails"
          :label="label"
          :data-test="testName"
          v-on="on"
          readonly
          :disabled="disabled"
          @click="showDatepicker()"
          @click:append="showDatepicker()"
          @click:clear="clearDatepicker()"
          :error="error"
          :error-messages="errorMessages"
        )
          template(v-slot:append)
            v-icon(:color="formattedDate ? 'primary' : ''") {{ icon }}
    slot(:process="datepickerProcess")
      v-date-picker(
        v-if="!timepicker"
        :value="value"
        :min="min"
        :max="max"
        @input="$emit('input', $event)"
        @change="datepickerProcess"
        :no-title="noTitle"
      )
      v-time-picker(
        v-else
        format="24hr"
        :value="value"
        @input="$emit('input', $event)"
        @change="datepickerProcess"
        :hideDetails="hideDetails"
      )
</template>

<script>
import { convertToDefaultDateFormat } from "@/util"

export default {
  props: {
    value: {
      required: true
    },
    disabled: Boolean,
    label: String,
    convertToDefault: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: 'mdi-calendar-today'
    },
    timepicker: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      default: true,
      type: Boolean
    },
    testName: String,
    error: Boolean,
    errorMessages: String,
    min: String,
    max: String,
    noTitle: Boolean,
    menuClasses: String
  },

  data: () => ({
    menu: false
  }),

  computed: {
    formattedDate() {
      if (!this.value) return
      if (this.convertToDefault) return convertToDefaultDateFormat(this.value)
      return this.value
    },
  },

  methods: {
    onChange() {
      this.$emit('change')
    },

    datepickerProcess() {
      this.menu = false
    },

    clearDatepicker() {
      this.$emit('input', '')
      this.onChange()
    },

    showDatepicker() {
      this.menu = true
    },
  }
}
</script>
